import {Component, computed, input, signal} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ICONS} from '../icons/icons.constants';

@Component({
  selector: 'input-field',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  template: `
    <div class="w-full max-w-sm min-w-[400px]">
      <div class="relative">
        <input
          [type]="showPassword() && type() === 'password' ? 'text' : type()"
          [formControl]="control()"
          placeholder=" "
          class="peer w-full bg-white text-slate-700 text-sm border border-slate-200 rounded-md px-4 pt-5 pb-1 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow cursor-text"
          [class.border-red-500]="shouldShowError()"
          (blur)="onTouched()"
        />

        <label
          class="absolute cursor-text left-4 top-4 text-slate-400 text-sm transition-all transform origin-left scale-100 peer-focus:scale-75 peer-focus:-translate-y-3 peer-focus:text-slate-600 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:text-slate-600"
        >
          {{ label() | titlecase }}
        </label>

        <svg
          [ngClass]="{ 'cursor-pointer': type() === 'password' }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="absolute w-5 h-4 top-1/2 right-4 -translate-y-1/2 text-slate-600"
          (click)="togglePasswordVisibility()"
        >
          <path [attr.d]="type() === 'password' ? (showPassword() ? ICONS.eye : ICONS.eyeSlash) : path()"></path>
        </svg>
      </div>
      @if (shouldShowError()) {
        @if (control().hasError('required')) {
          <span class="text-red-500 text-xs">{{ label() }} es requerido</span>
        }
      }
    </div>
  `
})
export class InputFieldComponent {
  public control = input.required<FormControl>();
  public label = input<string>();
  public type = input<string>('text');
  public path = input<string>();
  public showPassword = signal(false);

  private touched = false;

  onTouched() {
    this.touched = true;
  }

  shouldShowError(): boolean {
    return (
      this.control().invalid &&
      (this.control().dirty || this.touched) &&
      !this.control().pending
    );
  }

  togglePasswordVisibility() {
    if (this.type() === 'password') {
      this.showPassword.set(!this.showPassword());
    }
  }

  protected readonly ICONS = ICONS;
}
